import {WINDOW_RESIZE} from "../actions/actionType";

const INITIAL_STATE = {
  window: {height: undefined,width: undefined}
};

const reducerDisplay = (state=INITIAL_STATE,action) => {
  switch (action.type) {
    case WINDOW_RESIZE:
      return {...state, window: action.payload};
    default:
      return state;
  }
}

export default reducerDisplay;