import {
  GET_LINKED_INTEGRATION_PENDING_SUPPLIERS,
  GET_LINKED_INTEGRATION_PENDING_CUSTOMERS,
  SIGN_OUT
} from "../actions/actionType";

import _ from 'lodash';

const INITIAL_STATE = {};

const reducerLinkedIntegrationPendingData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LINKED_INTEGRATION_PENDING_CUSTOMERS:
      const currentCustomersData = _.get(state, action.payload?.cid, {});
      const updatedCustomersData = {...currentCustomersData,customers:action.payload.data};
      return {...state, [action.payload.cid]:updatedCustomersData}
    case GET_LINKED_INTEGRATION_PENDING_SUPPLIERS:
      const currentSuppliersData = _.get(state, action.payload?.cid, {});
      const updatedSuppliersData = {...currentSuppliersData,suppliers:action.payload.data};
      return {...state, [action.payload.cid]:updatedSuppliersData}
    case SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default reducerLinkedIntegrationPendingData;