import {GET_SUBSCRIPTION_CUSTOMER_DATA, PUSH_SUBSCRIPTION_CUSTOMER_DATA, SIGN_OUT} from "../actions/actionType";

const INITIAL_STATE = {};

/**
 * Managing state of subscription Customer, indexing state by the actual API id
 * @param state
 * @param action
 * @returns {Pick<{}, Exclude<never, [*][number]>>|{}|*}
 */
const reducerSubscriptionCustomers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_CUSTOMER_DATA:
      return {...state, ...action.payload}
    case PUSH_SUBSCRIPTION_CUSTOMER_DATA:
      return {...state, ...action.payload}
    case SIGN_OUT:
    default:
      return state;
  }
}

export default reducerSubscriptionCustomers;