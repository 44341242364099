import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    console.log({ErrorBoundary:error});
  }

  render() {
    if (this.state.hasError) {
      // Custom Error report
      return (
        <div className="container vh-100 d-flex align-items-center">
          <div className="row w-100 border-danger rounded-xxl shadow-lg">
            <div className={"col py-5 px-5"}>
              <div className={"row justify-content-center"}>
                <h4 className="text-danger">Error Encountered</h4>
              </div>
              <div className={"row mt-3 text-app-10 text-alert-danger justify-content-center px-5"}>
                <p>Unfortunately, we've run into an unexpected issue that we couldn't handle automatically. Please try the following steps to fix the problem:</p>
                <hr/>
                <ul>
                  <li><strong>Refresh your browser.</strong> Sometimes, simply reloading the page can resolve the issue.</li>
                  <li><strong>Clear your browser's cache and cookies.</strong> [Ctrl + F5] on Windows or [Cmd + Shift + R] on MacOS. This can help solve problems caused by outdated or corrupted files.</li>
                </ul>
                <p>If you continue to experience issues after trying these steps, please email us at <span className="alert-link">support@makershub.ai</span> with a brief description.  We will address and resolve the issue as soon as possible.</p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
