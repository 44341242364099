import XLSX from 'xlsx';
import _ from "lodash";
import phpServer from "../api/phpServer";
import dayjs from "dayjs";
import {useCallback, useRef} from "react";

//encode data for AJAX call
export const fEncodePostData = (postData) => {
  return encodeURIComponent(JSON.stringify(postData));
}

//handle JSON parse exception
export const fJSONParse = (response, defaultValue = null) => {
  try {
    if (response) {
      if (typeof(response) === "string") {
        return (JSON.parse(response));
      } else {
        return response;
      }
    } else {
      return defaultValue;
    }
  } catch {
    return defaultValue;
  }
}

//compile form address into address string
export const fStringAddress = (formAddress) => {
  const {address, city, country, state, zip} = formAddress;
  return (
    `${address}, ${city}, ${state} ${zip}, ${country}`
  );
}

//Get distance between 2 points using latitude and longitude
export const fGetDistanceFromGeo = (lat1, lon1, lat2, lon2) => {
  let p = 0.017453292519943295;    // Math.PI / 180
  let c = Math.cos;
  let a = 0.5 - c((lat2 - lat1) * p)/2 +
    c(lat1 * p) * c(lat2 * p) *
    (1 - c((lon2 - lon1) * p))/2;
  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

//format byte to KB and MB
export const fFormatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


// Parse an Excel File into JSON
export const fParseExcelFile = (file, callback) => {
  let reader = new FileReader();
  reader.onload = function(e) {
    let data = new Uint8Array(e.target.result);
    let workbook = XLSX.read(data, {type: 'array'});
    //Fetch the name of First Sheet.
    let firstSheet = workbook.SheetNames[0];
    let excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
    callback(excelRows);
  };
  reader.readAsArrayBuffer(file);
}

//Write into an Excel File from json array
//header - array, data: array of json
export const fWriteExcelFile = (data,headers,filename,wsCols=[],wsRows=[]) => {
  const ws = XLSX.utils.json_to_sheet(data,{header:headers});
  if (wsCols) {
    ws['!cols'] = wsCols;
  }
  if (wsRows) {
    ws['!rows'] = wsRows;
  }
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb,ws,"Sheet1");
  XLSX.writeFile(wb, `${filename}.xlsx`);
}

export const handleUTCdate = (date) => {
  const dateArray = date.split(/[- :]/);
  return new Date(Date.UTC(dateArray[0],dateArray[1]-1,dateArray[2],dateArray[3] ? dateArray[3] : 0,dateArray[4] ? dateArray[4] : 0,dateArray[5] ? dateArray[5] : 0));
}

export const getLocalDateTime = (utcDate) => {
  if (utcDate) {
    const utcDateObj = handleUTCdate(utcDate);
    return utcDateObj.toLocaleString('en-US');
  }
  return null;
}

//return today Date and offset
export const getDate = (offset=0,startDate=null,option=0) => {
  //convert days offset to millisecond; 1day = 86400000 ms
  const offsetMilliseconds = offset * 86400000;
  try {
    let date;
    if (startDate) {
      //split date to handle in safari
      const startDateUTC = handleUTCdate(startDate);
      // const startDateUTC = new Date(startDate + ' UTC');
      date = new Date(startDateUTC.getTime() + offsetMilliseconds);
    } else {
      date = new Date(Date.now() + offsetMilliseconds);
    }
    switch (option) {
      case 1:
        return (date.toLocaleTimeString('en-CA'));
      case 2:
        return (date.toLocaleString('en-CA'));
      case 3:
        return (date.toLocaleString('en-US'));
      default:
        return (date.toLocaleDateString('en-US'))
    }
  } catch {
    return "";
  }
}

//trim date time data
export const trimDateTime = (datetime) => {
  if (datetime) {
    const dateTimeStr = datetime.split(" ");
    return ({date:dateTimeStr[0],time:dateTimeStr[1]});
  } else {
    return {date:null,time:null};
  }
}

export const msToTime = (t) => {
  let cd = 24 * 60 * 60 * 1000,
    ch = 60 * 60 * 1000,
    d = Math.floor(t / cd),
    h = Math.floor( (t - d * cd) / ch),
    m = Math.round( (t - d * cd - h * ch) / 60000);
  if( m === 60 ){
    h++;
    m = 0;
  }
  if( h === 24 ){
    d++;
    h = 0;
  }
  let outputStr = "";
  let dayPlus = "";
  let hrPlus = "";
  if (d) {
    if (h && d>0) dayPlus = "+";
    outputStr = d + dayPlus + ` ${Math.abs(d)>1?"days":"day"}`;
  } else {
    if (h) {
      if (m && h>0) hrPlus = "+";
      outputStr = h + hrPlus + ` ${h>1?"hrs":"hr"}`;
    } else {
      outputStr = m + ` ${m>1?"mins":"min"}`;
    }
  }
  return (outputStr);
}

//check expiration and remaining time
export const getTimeRemained = (expiration) => {
  if (expiration) {
    const currentTime = new Date();
    const timeExpiration = handleUTCdate(expiration);
    const timeRemained = timeExpiration - currentTime;
    return (timeRemained);
  }
  return null;
}

export const getLastUpdated = (modified) => {
  if (modified) {
    const currentTime = new Date();
    //convert UTC time from server to local time
    // const lastUpdated = new Date(modified + ' UTC');
    const lastUpdated = handleUTCdate(modified);
    const msLastUpdated = (currentTime - lastUpdated);
    return (msToTime(msLastUpdated));
  }
  return null;
}

//dynamically load JS files
// dynamically load JavaScript files in our html with callback when finished
export const fLoadScript = (url, callback) => {
  let script = document.createElement("script"); // create script tag
  script.type = "text/javascript";

  // when script state is ready and loaded or complete we will call callback
  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }
  script.src = url; // load by url
  document.getElementsByTagName("head")[0].appendChild(script); // append to head
};

//group table array into each database columns for multiple records import
export const fPrepareImportData = (params, records, fixedParams= {}) => {
  let importArray = [];
  //process excel data - group data that is supposed to be an array
  records.forEach((record)=>{
    let itemImport = {};
    params.forEach((param)=>{
      if (param.name) {
        const data = _.get(record,param.name,null);
        // if the data is supposed to be array of JSON instead just JSON object
        if (param.dbArray) {
          const dbArrayIndex = param.dbCol.split('.');
          let dataArray = [];
          if (data !== null) {
            const stringData = String(data);
            dataArray = stringData.split(",").map((item)=>{
              return item.trim();
            })
          } else {
            dataArray = [""];
          }
          dataArray.forEach((data,index)=>{
            _.set(itemImport,[dbArrayIndex[0],'0',index,dbArrayIndex[1]],data);
          });
        } else {
          _.set(itemImport,param.dbCol,data);
        }
      }
    });
    Object.keys(fixedParams).forEach((key)=>{
      _.set(itemImport,key,fixedParams[key]);
    });
    importArray.push(itemImport);
  });
  //group each records together
  let processData = {};
  importArray.forEach((record)=>{
    Object.keys(record).forEach((key)=>{
      processData[key] = (processData[key] || []).concat(record[key]);
    })
  })
  return processData;
}


export const fServerPost = async (url, postData, action, cid = null) => {
  const formData = new FormData();
  formData.append("data", JSON.stringify(postData));
  if (cid) {
    formData.append("cid", cid);
  }
  try {
    const response = await phpServer.post(
      url,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          action: action,
        },
      }
    );

    // Server return fatal on expired session - force reload to bring log in screen
    if (response.data?.status === "Fatal") {
      window.location.reload(true);
    }

    return response.data;
  } catch (error) {
    console.error(error);
    // Handle error accordingly
  }
}

/**
 * @param url
 * @param files
 * @param postData
 * @param action
 * @param cid
 * @returns {Promise<any>}
 */
export const fServerPostFile = async (url, files, postData, action, cid = null) => {
  const formData = new FormData();

  if (Array.isArray(files)) {
    files.forEach((file) => {
      formData.append("file[]", file);
    });
  } else {
    formData.append("file", files);
  }

  const jsonPostData = JSON.stringify(postData);
  formData.append("data", jsonPostData);

  if (cid) {
    formData.append("cid", cid);
  }

  try {
    const {data} = await phpServer.post(
      url,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          action: action,
        },
      }
    );

    // Server return fatal on expired session - force reload to bring log in screen
    if (data?.status === "Fatal") {
      window.location.reload(true);
    }
    return data;
  } catch (error) {
    console.error(error);
    // Handle error accordingly
  }
}

export function CapFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export const DEFAULT_STATE = {status:"",msg:"",inProgress:false};

/**
 * Generate random hex
 * @returns {string}
 */
export function generateRandomHex(length) {
  return Array.from(crypto.getRandomValues(new Uint8Array(length)))
  .map(b => b.toString(16).padStart(2, '0'))
  .join('');
}

//combine different column to generate an element name by id from listById
export const getIntNameById = (id, listById, params={}) => {
  const defaultParams = {
    nameCol:['name'],
    fullNameCol: 'fullName',
    nameLink:' '
  }
  const activeParams = {...defaultParams,...params}
  let name = '';
  const element = listById[id];
  if (element) {
    const nameColLength = activeParams?.nameCol?.length;
    if (nameColLength) {
      activeParams.nameCol.forEach((col,i)=>{
        if (element[col]) {
          name += element[col];
          if (i<(nameColLength-1)) {
            name += activeParams?.nameLink;
          }
        }
      })
    }
    if (!name || name === "null") {
      return element[activeParams.fullNameCol] ?? '';
    }
  }
  return name;
}

//recursive through parentId to get the fullName from initial parent to final child
export const getIntFullNameById = (id, listById, childName='', params={}) => {
  const defaultParams = {
    nameCol:['name'],
    fullNameCol: 'fullName',
    nameLink: ' ',
    parentIdCol:'parentId',
    parentChildLink: ':'
  }
  const activeParams = {...defaultParams,...params};
  let activeFullName = getIntNameById(id,listById,activeParams);
  if (childName) {
    activeFullName += activeParams.parentChildLink + childName;
  }
  if (listById[id] && listById[id][activeParams.parentIdCol] && (id !== listById[id][activeParams.parentIdCol])) {
    return getIntFullNameById(listById[id][activeParams.parentIdCol],listById,activeFullName,params)
  }
  return activeFullName;
}

//parse through intList to create a name, value and fullName for select options
export function parseIntegrationList(list,params={}) {
  if (list && list.length) {
    const defaultParams = {
      nameCol:['name'],
      nameLink: ' ',
      parentIdCol:'parentId',
      parentChildLink: ':',
      idCol:'id',
      activeCol:'active',
      fullNameCol:'fullName',
      retValue:'value',
      retName:'name',
      retFullName:'fullName',
      retNameRaw:'nameRaw'
    }
    const activeParams = {...defaultParams,...params};
    const {parentChildLink, idCol, activeCol, fullNameCol, retValue, retName,retFullName, retNameRaw} = activeParams;
    const listById = _.mapKeys(list,idCol);
    let parsedList = [];
    list.forEach((e)=>{
      //if having valid id and is active
      //if (e[idCol] && (Number(e[activeCol]) === 1)) {
      if (e[idCol]) {
        let fullName;
        if (e[fullNameCol] && (activeParams.nameCol === defaultParams.nameCol)) {
          fullName = e[fullNameCol]
        } else {
          fullName = getIntFullNameById(e[idCol],listById,'',activeParams) ?? '';
        }
        const fullNameArray = fullName.split(parentChildLink);
        let name = '';
        let nameRaw = '';
        const fullNameLength = fullNameArray.length;
        fullNameArray.forEach((nameElement,i)=>{
          switch (i) {
            case (fullNameLength - 1):
              name += nameElement;
              nameRaw = nameElement;
              break;
            case (fullNameLength - 2):
              name += ' • ';
              break;
            default:
              name += ' ';
          }
        })
        parsedList.push({
          [retValue]:e[idCol],
          [retName]:name,
          [retFullName]:fullName,
          [retNameRaw]:nameRaw
        })
      }
    })
    return _.sortBy(parsedList,retFullName);
  }
  return []
}

export function getBigrams(string) {
  if (typeof string === 'string' && string.length >=2) {
    let bigrams = {};
    for (let i = 0; i < string.length - 1; i++) {
      const bigram = string.substring(i, i + 2);
      if (bigrams[bigram]) {
        bigrams[bigram] = bigrams[bigram] + 1;
      } else {
        bigrams[bigram] = 1;
      }
    }
    return bigrams;
  }
  return null;
}

//calculate diceCoefficient between 2 strings
export function diceRating(first, second, firstBigramsPreset=null) {
  if (typeof first !== 'string' || typeof second !== 'string') return 0;
  if (first === second) return 1; // identical or empty
  const firstLength = first.length;
  const secondLength = second.length;
  if (firstLength < 2 || secondLength < 2) return 0; // if either is a 0-letter or 1-letter string

  const firstBigrams = firstBigramsPreset ?
    {...firstBigramsPreset} :
    getBigrams(first);

  let intersectionSize = 0;
  for (let i = 0; i < secondLength - 1; i++) {
    const bigram = second.substring(i, i + 2);
    if (firstBigrams[bigram] && firstBigrams[bigram] > 0) {
      intersectionSize++;
      firstBigrams[bigram]--;
    }
  }

  return (2.0 * intersectionSize) / (firstLength + secondLength - 2);
}

export function findBestMatch(mainString,targetArray,params={}) {

  const defaultParams = {
    caseSensitive: false,
    key: null,
    subString: false,
    limit: 0.1,
    noSpaceRating:0.975,
    subStringRating:0.95
  }

  const activeParams = {...defaultParams,...params};

  const mainStringNoSpace = mainString.replace(/\s+/g,'');
  const mainStringLowerCase = mainStringNoSpace.toLowerCase();
  const mainStringNoSpaceBigrams = getBigrams(mainStringNoSpace);
  const mainStringLowerCaseBigrams = getBigrams(mainStringLowerCase);

  const ratings = [];
  let bestMatchIndex = 0;

  targetArray.forEach((e,i)=>{
    const targetString = (activeParams.key ? e[activeParams.key] : e) ?? '';
    let currentRating;
    let target;
    //only a direct equal can return a 1
    if (mainString === targetString) {
      currentRating = 1;
      target = targetString;
    } else {
      const targetStringNoSpace = targetString.replace(/\s+/g,'')
      let noSpaceRating = activeParams.caseSensitive ?
        diceRating(mainStringNoSpace,targetStringNoSpace,mainStringNoSpaceBigrams) :
        diceRating(mainStringLowerCase,targetStringNoSpace.toLowerCase(), mainStringLowerCaseBigrams);
      noSpaceRating = activeParams.noSpaceRating * noSpaceRating;

      currentRating = noSpaceRating;
      target = targetStringNoSpace;

      if ((noSpaceRating > activeParams.limit) && activeParams.subString) {
        const targetSubStringArray = targetString.split(/\s+/g);
        //check if subString rating is better than full comparison
        if (targetSubStringArray.length > 1) {
          targetSubStringArray.forEach((subString) => {
            let currentSubRating = activeParams.caseSensitive ?
              diceRating(mainStringNoSpace, subString,mainStringNoSpaceBigrams) :
              diceRating(mainStringLowerCase, subString.toLowerCase(), mainStringLowerCaseBigrams)
            currentSubRating = activeParams.subStringRating * currentSubRating;

            if (currentSubRating > currentRating) {
              currentRating = currentSubRating;
              target = subString;
            }
          })
        }
      }
    }
    ratings.push({element:e, rating:currentRating, target:target})
    if (currentRating > ratings[bestMatchIndex].rating) {
      bestMatchIndex = i;
    }
  })

  const bestMatch = ratings[bestMatchIndex];

  return ({
    matched: bestMatch.rating >= 1,
    bestMatch:bestMatch,
    ratings:_.orderBy(ratings,'rating','desc'),
  })
}

export function uniqid(prefix="",postFix="") {
  const id = Math.round(Date.now()*Math.random()).toString(36);
  return `${prefix}${id}${postFix}`;
}

export function generateUniqueString(length, withSymbol = false, withLowerCase = false) {
  const numbers = '0123456789';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const symbols = '!@#$%^&*()';

  let source = numbers + characters;
  if (withSymbol) {
    source += symbols;
  }
  if (withLowerCase) {
    source += characters.toLowerCase();
  }
  const sourceLastIndex = source.length - 1;
  let uniqueString = "";
  for (let i = 0; i < length; i++) {
    uniqueString += source.charAt(Math.floor(Math.random() * (sourceLastIndex + 1)));
  }
  return uniqueString;
}


export const formatDecimal = (number,digits) => {
  const mul = Math.pow(10,parseFloat(digits));
  return Math.round(parseFloat(number)*mul)/mul;
}

export const randomFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

//dynamic comparison
/**
 *
 * @param a
 * @param b
 * @param operator
 * @param separator
 * @param combinator
 * @returns {null|boolean|boolean|*|boolean}
 */
export const dynamicOperator = (a,b,operator,separator=",",combinator="+") => {
  switch (operator) {
    case "<":
      return Number(a) < Number(b);
    case "<=":
      return Number(a) <= Number(b);
    case ">":
      return Number(a) > Number(b);
    case ">=":
      return Number(a) >= Number(b);
    case "==":
      return a == b;
    case "===":
      return a === b;
    case "includes":
    case "include":
      //case sensitive
      return String(a).includes(String(b));
    case "includesAll":
    case "includeAll":
      //case insensitive
      return dynamicOperator(String(a).toLowerCase(),String(b).toLowerCase(),"includes");
    case "excludes":
    case "exclude":
      //case sensitive
      return !dynamicOperator(a,b,"includes");
    case "excludesAll":
    case "excludeAll":
      //case insensitive
      return !dynamicOperator(a,b,"includesAll");
    case "includesNeedle":
    case "includeNeedle":
      //look for inclusion in a string (separated by comma, combined by +)
      const needles = String(b).split(separator);
      let match = false;
      _.forEach(needles,(needle)=>{
        if (needle.trim() !== "") {
          //explode combination (+)
          const needleArray = needle.trim().split(combinator);
          let needleMatch = true;
          _.forEach(needleArray,(needleValue)=>{
            if (!String(a).includes(needleValue.trim())) {
              needleMatch = false;
              return false;
            }
          })
          if (needleMatch) {
            match = true;
            return false;
          }
        }
      })
      return match;
    case "includesNeedleAll":
    case "includeNeedleAll":
      return dynamicOperator(String(a).toLowerCase(),String(b).toLowerCase(),"includesNeedle",separator,combinator);
    case "excludesNeedle":
    case "excludeNeedle":
      return !dynamicOperator(a,b,"includesNeedle",separator,combinator);
    case "excludesNeedleAll":
    case "excludeNeedleAll":
      return !dynamicOperator(a,b,"includesNeedleAll",separator,combinator);
    case "isBefore":
      if (dayjs(a).isValid() && dayjs(b).isValid()) {
        return dayjs(a).isBefore(b)
      }
      return null;
    case "isSameOrBefore":
      if (dayjs(a).isValid() && dayjs(b).isValid()) {
        return dayjs(a).isBefore(b) || dayjs(a).isSame(b)
      }
      return null;
    case "isAfter":
      if (dayjs(a).isValid() && dayjs(b).isValid()) {
        return dayjs(a).isAfter(b)
      }
      return null;
    case "isSameOrAfter":
      if (dayjs(a).isValid() && dayjs(b).isValid()) {
        return dayjs(a).isAfter(b) || dayjs(a).isSame(b)
      }
      return null;
    case "matchInside":
      //in string a, separated by separator (comma), look for an exact match in string b, separated by combinator
      const aArray = String(a).split(separator);
      const bArray = String(b).split(combinator);
      let insideMatch = false;
      _.forEach(aArray,(aElement)=> {
        let abMatch = false;
        _.forEach(bArray, (bElement) => {
          if (String(aElement).trim() === String(bElement).trim()) {
            abMatch = true;
            return false;
          }
        })
        if (abMatch) {
          insideMatch = true;
          return false;
        }
      })
      return insideMatch;
    case "noMatchInside":
      return !dynamicOperator(a, b, "matchInside", separator, combinator);
    default:
      return null;
  }
}

/**
 *
 * @param a
 * @param b
 * @param {('add'|'plus'|'subtract'|'sub'|'minus'|'multiply'|'mul'|'divide'|'div')} operation
 * @param scale
 * @returns {boolean|number}
 */
export function bcmath (a,b,operation,scale=null) {
  // Convert inputs to numbers
  const num1 = Number(a);
  const num2 = Number(b);

  // Check for valid numbers
  if (isNaN(num1) || isNaN(num2)) {
    return false;
  }
  let result;
  // Perform the specified operation
  switch (operation) {
    case 'add':
    case 'plus':
      result = num1 + num2;
      break;
    case 'subtract':
    case 'sub':
    case 'minus':
      result = num1 - num2;
      break;
    case 'multiply':
    case 'mul':
      result = num1 * num2;
      break;
    case 'divide':
    case 'div':
      if (num2 === 0) {
        return false; // Avoid division by zero
      }
      result = num1 / num2;
      break;
    default:
      return false; // Invalid operation
  }
  // Round the result based on the scale variable
  let mathScale;
  if (scale) {
    mathScale = Number(scale);
  } else {
    //check max scale of the 2 number
    const scaleA = (String(a).split('.')[1] || '').length;
    const scaleB = (String(b).split('.')[1] || '').length;
    mathScale = Math.max(scaleA,scaleB)
  }
  const scaleMul = Math.pow(10, mathScale);
  result = Math.round(result * scaleMul) / scaleMul;
  return result;
}

//get data from local storage
export function getLocalStorage(key,defaultValue={}) {
  return fJSONParse(localStorage.getItem(key),defaultValue)
}

//set data to storage
export function setLocalStorage(key,data) {
  localStorage.setItem(key,JSON.stringify(data))
}

export function checkBoolStr(str) {
  if (typeof str !== 'string') {
    return Boolean(str);
  }
  switch (str.toLowerCase().trim()) {
    case "false":
    case "0":
    case "":
      return false;
    default:
      return true;
  }
}

export function getUtcTimestamp($dateOnly = false) {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() returns 0-11
  const day = String(now.getUTCDate()).padStart(2, '0');
  const hours = String(now.getUTCHours()).padStart(2, '0');
  const minutes = String(now.getUTCMinutes()).padStart(2, '0');
  const seconds = String(now.getUTCSeconds()).padStart(2, '0');
  if ($dateOnly) {
    return `${year}-${month}-${day}`;
  }
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function setStateKey(paths, value, currentState, setState) {
  let newState;
  if (_.isArray(paths)) {
    const pathPointerStates = [];
    const pathPointer = [...paths];
    //capture end state
    const endPath = pathPointer.pop();
    pathPointerStates.push({[endPath]:value});
    //loop backward to the beginning path
    const pathLoopArray = _.reverse([...pathPointer]);
    _.forEach(pathLoopArray,(path,index)=>{
      const currentPathValue = _.get(currentState,pathPointer,{});
      const newPathValue = {...currentPathValue, ...pathPointerStates[index]}
      //push new value for reference
      pathPointerStates.push({[path]:newPathValue});
      //pop pointer value
      pathPointer.pop();
    })
    newState = {...currentState,..._.last(pathPointerStates)}
  } else {
    newState = {...currentState,[paths]:value};
  }
  setState(newState);
}

export function useDebounce(func, wait) {
  // Use useRef to store the function
  const functionRef = useRef(func);
  functionRef.current = func;
  // Use useCallback to return a stable debounced function
  const debouncedFunction = useCallback((...args) => {
    const later = () => {
      clearTimeout(timerRef.current);
      functionRef.current(...args);
    };
    // Clear the timer on every call and reset it
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(later, wait);
  }, [wait]);  // Only recreate the debounced function if 'wait' changes
  // Use useRef to store the timeout ID
  const timerRef = useRef(null);
  return debouncedFunction;
}

/**
 * Convert an object to ordered key, value array
 * @param {Object} object
 * @param iteratee
 * @param sortOrder
 * @returns {{keyValueArray: *[], isOrdered: boolean}}
 */
export function objectToOrderedKeyValueArray(object, iteratee = ['order'], sortOrder = 'asc') {
  let result = [];
  let isOrdered = false;
  _.forOwn(object, (value, key) => {
    let element = {key: key, value: value};
    const order = _.get(value, iteratee, null) ?? null;
    if (order !== null) {
      isOrdered = true;
      element.order = parseInt(order);
    }
    result.push(element);
  })
  return {
    keyValueArray: _.orderBy(result, ['order'], sortOrder),
    isOrdered: isOrdered
  };
}

export function numberSimilarity(a, b, signSensitive = false) {
  const num1 = signSensitive ? Number(a) : Math.abs(Number(a));
  const num2 = signSensitive ? Number(b) : Math.abs(Number(b));
  if (num1 === num2) return 1;
  const diff = Math.abs(num1 - num2);
  const maxVal = Math.max(num1, num2);
  return (1 - diff/maxVal);
}
