import {
  PUSH_INTEGRATION_ERRORS,
  REFRESH_INTEGRATION_ERRORS,
  GET_INTEGRATION_ERRORS,
  SIGN_OUT,
} from "../actions/actionType";

const INITIAL_STATE = {};

const reducerIntegrationErrors = (state = INITIAL_STATE, action) => {
  //return state
  switch (action.type) {
    case GET_INTEGRATION_ERRORS:
      return {...state, ...action.payload};
    case REFRESH_INTEGRATION_ERRORS:
      return action.payload;
    case PUSH_INTEGRATION_ERRORS:
      return {...state, ...action.payload}
    case SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default reducerIntegrationErrors;
