import {GET_COMPANY_USERS, PUSH_COMPANY_USERS, DELETE_COMPANY_USER, SIGN_OUT} from "../actions/actionType";
import _ from 'lodash';

const INITIAL_STATE = {};

const reducerUsers = (state=INITIAL_STATE,action) => {
  switch (action.type) {
    case GET_COMPANY_USERS:
      return {...state, ...action.payload};
    case PUSH_COMPANY_USERS:
      return {...state, ...action.payload};
    case DELETE_COMPANY_USER:
      return _.omit(state,action.payload.id)
    case SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default reducerUsers;
