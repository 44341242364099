import {
  GET_SUPPLIER_TAX_FORMS,
  PUSH_SUPPLIER_TAX_FORMS,
  DELETE_SUPPLIER_TAX_FORM, SIGN_OUT
} from "../actions/actionType";

import _ from 'lodash';

const INITIAL_STATE = {};

const reducerTaxForms = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SUPPLIER_TAX_FORMS:
      return {...state, ...action.payload};
    case PUSH_SUPPLIER_TAX_FORMS:
      return {...state, ...action.payload};
    case DELETE_SUPPLIER_TAX_FORM:
      return _.omit(state, action.payload.id);
    case SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default reducerTaxForms;