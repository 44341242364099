import {GET_INTEGRATION_PENDING_SUPPLIERS, GET_INTEGRATION_PENDING_CUSTOMERS, SIGN_OUT} from "../actions/actionType";

const INITIAL_STATE = {};

const reducerIntegrationPendingData = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INTEGRATION_PENDING_CUSTOMERS:
      return {...state, customers: action.payload};
    case GET_INTEGRATION_PENDING_SUPPLIERS:
      return {...state, suppliers: action.payload};
    case SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default reducerIntegrationPendingData;