import {GET_INVOICE_PAYABLES, PUSH_INVOICE_PAYABLES, DELETE_INVOICE_PAYABLES, SIGN_OUT} from "../actions/actionType";
import _ from 'lodash';

const INITIAL_STATE = {};

const reducerInvoicePayables = (state=INITIAL_STATE,action) => {
  switch (action.type) {
    case GET_INVOICE_PAYABLES:
      return action.payload;
    case PUSH_INVOICE_PAYABLES:
      return {...state, ...action.payload};
    case DELETE_INVOICE_PAYABLES:
      return _.omit(state,action.payload.id);
    case SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default reducerInvoicePayables;
