import {GET_INVOICES_OCR, REFRESH_INVOICES_OCR, PUSH_INVOICES_OCR, DELETE_INVOICE_OCR, SIGN_OUT} from "../actions/actionType";
import _ from "lodash";

const INITIAL_STATE = {};

const reducerInvoicesOCR = (state=INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INVOICES_OCR:
      return {...state,...action.payload};
    case REFRESH_INVOICES_OCR:
      return action.payload;
    case PUSH_INVOICES_OCR:
      return {...state,...action.payload};
    case DELETE_INVOICE_OCR:
      return _.omit(state,action.payload.id);
    case SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default reducerInvoicesOCR;