//assigned by server with starting a session
export const COOKIE_PHP_SESSION_ID = 'PHPSESSID';

//session id of an authorized user
export const COOKIE_AUTH_USER_SESSION_ID = "AuthUserSessionId";

export const URL_SERVER_AUTH = "/api/account/auth.php";

export const URL_SERVER_COMPANY = "/api/account/company.php";

export const URL_SERVER_SUPPORT = "/api/account/support.php";

export const URL_SERVER_ADMIN = "/api/account/adminSettings.php";

export const SERVER_DATA_EXT_COMPANIES = "/api/data/extCompanies.php";

export const SERVER_DATA_SUPPLIERS = "/api/data/suppliers.php";

export const SERVER_DATA_BANKS = "/api/data/banks.php";

export const SERVER_DATA_CUSTOMERS = "/api/data/customers.php"

export const SERVER_DATA_ITEMS = "/api/data/items.php";

export const SERVER_DATA_DASHBOARD = "api/data/dashboard.php";

export const SERVER_DATA_BILLING = "api/data/billing.php";

export const SERVER_DATA_SUBSCRIPTIONS = "api/data/subscriptions.php";

export const SERVER_DATA_OUTGOING_PAYMENTS = "/api/data/outgoingPayments.php";

export const SERVER_DATA_INVOICES = "/api/data/invoices.php";

export const SERVER_DATA_INVOICE_TEMPLATES = "/api/data/invoiceTemplates.php";

export const SERVER_DATA_PAYABLES = "api/data/payables.php";

export const SERVER_DATA_PAYMENTS = "/api/data/payments.php";

export const SERVER_DATA_FILES = "/api/data/files.php";

export const SERVER_DATA_TIME_TRACKING = "/api/data/timeTracking.php";

export const SERVER_INTEGRATION_DATA = "/api/integration/data.php";

export const SERVER_INTEGRATION_BILLCOM = "/api/integration/billcom.php";

export const SERVER_INTEGRATION_QB_DESKTOP = "/api/integration/qbDesktop.php";

export const SERVER_INTEGRATION_QB_ONLINE = "/api/integration/qbOnline.php";

export const SERVER_PUBLIC_BANKS = "/api/public/banks.php";

export const SERVER_PUBLIC_INVOICES = "/api/public/invoices.php";

export const SERVER_PUBLIC_SIGN_UP = "/api/public/signup.php";

export const SERVER_PUBLIC_MAP = "/api/public/map.php";

export const SERVER_TOKEN_TAX_FORMS = "/api/token/taxForms.php";

export const SERVER_TOKEN_PAYABLE_OUT_REFUND = "/api/token/payableOutRefund.php";
