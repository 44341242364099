//User Auth
export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const SIGN_IN_COOKIE = 'SIGN_IN_COOKIE';
export const SIGN_IN_UID = 'SIGN_IN_UID';
export const SIGN_OUT = 'SIGN_OUT';
export const ADD_CID_TO_AUTH = 'ADD_CID_TO_AUTH';
export const ADD_INTEGRATION = 'ADD_INTEGRATION';
export const REMOVE_INTEGRATION = 'REMOVE_INTEGRATION';

//Company Info
export const GET_COMPANY_INFO = 'GET_COMPANY_INFO';
export const GET_COMPANY_USERS = 'GET_COMPANY_USERS';
export const GET_COMPANY_INFO_ERROR = 'GET_COMPANY_INFO_ERROR';
export const PUSH_COMPANY_INFO = 'PUSH_COMPANY_INFO';
export const PUSH_COMPANY_USERS = 'PUSH_COMPANY_USERS';
export const DELETE_COMPANY_USER = 'DELETE_COMPANY_USER';

//Company Account
export const GET_PAYMENT_ACCOUNT_INFO = 'GET_COMPANY_ACCOUNT_INFO';
export const PUSH_PAYMENT_ACCOUNT_INFO = 'PUSH_COMPANY_ACCOUNT_INFO';
export const DELETE_PAYMENT_ACCOUNT_INFO = 'DELETE_COMPANY_ACCOUNT_INFO'

//Company Credits
export const GET_COMPANY_CREDITS_INFO = 'GET_COMPANY_CREDITS_INFO';

//Company Bank Account
export const GET_COMPANY_BANK_ACCOUNT_INFO = "GET_COMPANY_BANK_ACCOUNT_INFO";
export const PUSH_COMPANY_BANK_ACCOUNT_INFO = "PUSH_COMPANY_BANK_ACCOUNT_INFO";
export const DELETE_COMPANY_BANK_ACCOUNT_INFO = "DELETE_COMPANY_BANK_ACCOUNT_INFO";

export const GET_SUPPLIER_BANK_ACCOUNT_INFO = "GET_SUPPLIER_BANK_ACCOUNT_INFO";
export const PUSH_SUPPLIER_BANK_ACCOUNT_INFO = "PUSH_SUPPLIER_BANK_ACCOUNT_INFO";
export const DELETE_SUPPLIER_BANK_ACCOUNT_INFO = "DELETE_SUPPLIER_BANK_ACCOUNT_INFO";

//Tax Forms
export const GET_SUPPLIER_TAX_FORMS = "GET_SUPPLIER_TAX_FORMS";
export const PUSH_SUPPLIER_TAX_FORMS = "PUSH_SUPPLIER_TAX_FORMS";
export const DELETE_SUPPLIER_TAX_FORM = "DELETE_SUPPLIER_TAX_FORM";

//Items
export const GET_ITEMS_INFO = 'GET_ITEMS_INFO';
export const PUSH_ITEMS_INFO = 'PUSH_ITEMS_INFO';
export const DELETE_ITEM = 'DELETE_ITEM';

//Suppliers
export const REFRESH_SUPPLIERS_INFO = 'REFRESH_SUPPLIERS_INFO';
export const GET_SUPPLIERS_INFO = 'GET_SUPPLIERS_INFO';
export const PUSH_SUPPLIER_INFO = 'PUSH_SUPPLIER_INFO';
export const DELETE_SUPPLIER = "DELETE_SUPPLIER";

//Customers
export const GET_CUSTOMERS_INFO = 'GET_CUSTOMERS_INFO';
export const PUSH_CUSTOMER_INFO = 'PUSH_CUSTOMER_INFO';
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";

//display monitoring
export const WINDOW_RESIZE = 'WINDOW_RESIZE';

//Invoices
export const GET_INVOICES_INFO = 'GET_INVOICES_INFO';
export const REFRESH_INVOICES_INFO = 'REFRESH_INVOICES_INFO';
export const PUSH_INVOICES_INFO = 'PUSH_INVOICES_INFO';
export const PUSH_INVOICE_DOCUMENT_INFO = 'PUSH_INVOICE_DOCUMENT_INFO';
export const PUSH_PARENT_INVOICE_INFO = 'PUSH_PARENT_INVOICE_INFO';
export const DELETE_INVOICE = 'DELETE_INVOICE';
export const GET_INVOICES_OCR = 'GET_INVOICES_OCR';
export const REFRESH_INVOICES_OCR = 'REFRESH_INVOICES_OCR';
export const PUSH_INVOICES_OCR = 'PUSH_INVOICES_OCR';
export const DELETE_INVOICE_OCR = 'DELETE_INVOICE_OCR';
export const COMBINE_INVOICES = 'COMBINE_INVOICES';

//Invoice Templates
export const GET_INVOICE_TEMPLATES = "GET_INVOICE_TEMPLATES";
export const PUSH_INVOICE_TEMPLATE = "PUSH_INVOICE_TEMPLATE";
export const DELETE_INVOICE_TEMPLATE = "DELETE_INVOICE_TEMPLATE";

export const GET_INVOICE_PAYABLES = 'GET_INVOICE_PAYABLES';
export const PUSH_INVOICE_PAYABLES = 'PUSH_INVOICE_PAYABLES';
export const DELETE_INVOICE_PAYABLES = 'DELETE_INVOICE_PAYABLES';

export const GET_PAYABLE_OUT = 'GET_PAYABLE_OUT';
export const REFRESH_PAYABLE_OUT = 'REFRESH_PAYABLE_OUT';
export const PUSH_PAYABLE_OUT = 'PUSH_PAYABLE_OUT';
export const DELETE_PAYABLE_OUT = 'DELETE_PAYABLE_OUT';

//Integrations
export const PUSH_INTEGRATION_DATA = 'PUSH_INTEGRATION_DATA';
export const REMOVE_INTEGRATION_DATA = 'PUSH_INTEGRATION_DATA';

export const PUSH_LINKED_INTEGRATION_DATA = 'PUSH_LINKED_INTEGRATION_DATA';
export const REMOVE_LINKED_INTEGRATION_DATA = 'PUSH_LINKED_INTEGRATION_DATA';

export const GET_INTEGRATION_PENDING_SUPPLIERS = "GET_INTEGRATION_PENDING_SUPPLIERS";
export const GET_INTEGRATION_PENDING_CUSTOMERS = "GET_INTEGRATION_PENDING_CUSTOMERS";
export const GET_LINKED_INTEGRATION_PENDING_SUPPLIERS = "GET_LINKED_INTEGRATION_PENDING_SUPPLIERS";
export const GET_LINKED_INTEGRATION_PENDING_CUSTOMERS = "GET_LINKED_INTEGRATION_PENDING_CUSTOMERS";

export const GET_INTEGRATION_ERRORS = 'GET_INTEGRATION_ERRORS';
export const REFRESH_INTEGRATION_ERRORS = 'REFRESH_INTEGRATION_ERRORS';
export const PUSH_INTEGRATION_ERRORS = 'PUSH_INTEGRATION_ERRORS';

//Subscriptions
export const GET_SUBSCRIPTION_DATA = "GET_SUBSCRIPTION_DATA";
export const PUSH_SUBSCRIPTION_DATA = "PUSH_SUBSCRIPTION_DATA";
export const REMOVE_SUBSCRIPTION_DATA = "REMOVE_SUBSCRIPTION_DATA";

//Subscription Customers - This is company External Link, index by api customer_id
export const GET_SUBSCRIPTION_CUSTOMER_DATA = "GET_SUBSCRIPTION_CUSTOMER_DATA";
export const PUSH_SUBSCRIPTION_CUSTOMER_DATA = "PUSH_SUBSCRIPTION_CUSTOMER_DATA";

//company emails
export const GET_MAIL_RECORDS = 'GET_MAIL_RECORDS';
export const REFRESH_MAIL_RECORDS = 'REFRESH_MAIL_RECORDS';
export const PUSH_MAIL_RECORDS = 'PUSH_MAIL_RECORDS';
