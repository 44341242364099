import {
  GET_COMPANY_BANK_ACCOUNT_INFO,
  PUSH_COMPANY_BANK_ACCOUNT_INFO,
  DELETE_COMPANY_BANK_ACCOUNT_INFO,
  SIGN_OUT,
  GET_SUPPLIER_BANK_ACCOUNT_INFO, PUSH_SUPPLIER_BANK_ACCOUNT_INFO, DELETE_SUPPLIER_BANK_ACCOUNT_INFO
} from "../actions/actionType";
import _ from "lodash";

const INITIAL_STATE = {};

const reducerBankAccounts = (state=INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_BANK_ACCOUNT_INFO:
    case GET_SUPPLIER_BANK_ACCOUNT_INFO:
      return {...state, ...action.payload};
    case PUSH_COMPANY_BANK_ACCOUNT_INFO:
    case PUSH_SUPPLIER_BANK_ACCOUNT_INFO:
      return {...state, ...action.payload};
    case DELETE_COMPANY_BANK_ACCOUNT_INFO:
    case DELETE_SUPPLIER_BANK_ACCOUNT_INFO:
      return _.omit(state,action.payload.id);
    case SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default reducerBankAccounts;