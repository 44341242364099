import {GET_SUBSCRIPTION_DATA, PUSH_SUBSCRIPTION_DATA, REMOVE_SUBSCRIPTION_DATA, SIGN_OUT} from "../actions/actionType";
import _ from 'lodash';

const INITIAL_STATE = {};

/**
 * Managing state of subscriptions, indexing state by c_id
 * @param state
 * @param action
 * @returns {Pick<{}, Exclude<never, [*][number]>>|{}|*}
 */
const reducerSubscriptions = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_DATA:
      return {...state, ...action.payload}
    case PUSH_SUBSCRIPTION_DATA:
      return {...state, ...action.payload}
    case REMOVE_SUBSCRIPTION_DATA:
      return _.omit(state,action.payload.c_id)
    case SIGN_OUT:
    default:
      return state;
  }
}

export default reducerSubscriptions;