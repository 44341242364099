import {DELETE_CUSTOMER, GET_CUSTOMERS_INFO, PUSH_CUSTOMER_INFO, SIGN_OUT} from "../actions/actionType";
import _ from 'lodash';

const INITIAL_STATE = {};

const reducerCustomers = (state=INITIAL_STATE,action) => {
  switch (action.type) {
    case GET_CUSTOMERS_INFO:
      return {...action.payload};
    case PUSH_CUSTOMER_INFO:
      return {...state, ...action.payload};
    case DELETE_CUSTOMER:
      return _.omit(state,action.payload.id);
    case SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default reducerCustomers;
