import {
  GET_PAYABLE_OUT,
  PUSH_PAYABLE_OUT,
  DELETE_PAYABLE_OUT,
  SIGN_OUT,
  REFRESH_PAYABLE_OUT
} from "../actions/actionType";
import _ from 'lodash';

const INITIAL_STATE = {};

const reducerPayableOut = (state=INITIAL_STATE,action) => {
  switch (action.type) {
    case GET_PAYABLE_OUT:
      return {...state,...action.payload};
    case REFRESH_PAYABLE_OUT:
      return action.payload;
    case PUSH_PAYABLE_OUT:
      return {...state, ...action.payload};
    case DELETE_PAYABLE_OUT:
      return _.omit(state,action.payload.id);
    case SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default reducerPayableOut;