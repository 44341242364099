import React from "react";

export const InProgress = (props) => {
  if (props.type) {
    return (
      <span className={`spinner-border ${props.variant ? props.variant : "text-primary"}`}/>
    )
  } else {
    return (
      <span className={`spinner-grow ml-1 mb-2 ${props.variant ? props.variant : "text-primary"}`} style={{width:"0.5rem",height:"0.5rem"}}/>
    )
  }
}

export const RFQCategory = (props) => {
  const rfqSubCat = (subCat) => {
    if (subCat) {
      return (
        <>
          <i className={"bi-caret-right mx-1"}/>
          <span className={"btn btn-sm py-0 btn-danger disabled"}>{props.rfq_subcat}</span>
        </>
      )
    }
  }
  return (
    <>
      <span className={"btn btn-sm py-0 btn-primary disabled"}>{props.rfq_cat}</span>
      {rfqSubCat(props.rfq_subcat)}
    </>
  )

}

export const roundToNearestTenth = (number) => {
  if (number < 10) {
    return number;
  } else if (number < 100) {
    return (Math.ceil(number / 10) * 10);
  } else if (number < 1000) {
    return (Math.ceil(number / 100) * 100);
  } else if (number < 10000) {
    return (Math.ceil(number / 1000) * 1000);
  }
  return (Math.ceil(number / 10000) * 10000);
}

