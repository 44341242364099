import {PUSH_LINKED_INTEGRATION_DATA, REMOVE_LINKED_INTEGRATION_DATA, SIGN_OUT} from "../actions/actionType";
import _ from 'lodash';

const INITIAL_STATE = {};

const reducerLinkedIntegrationData = (state=INITIAL_STATE, action) => {
  switch (action.type) {
    case PUSH_LINKED_INTEGRATION_DATA:
      const currentData = _.get(state,action.payload?.cid,{});
      const updatedData = {...currentData,...action.payload.data};
      return {...state, [action.payload?.cid]:updatedData};
    case REMOVE_LINKED_INTEGRATION_DATA:
    case SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
export default reducerLinkedIntegrationData;
