import {combineReducers} from 'redux';
import reducerAuth from "./reducerAuth";
import reducerDisplay from "./reducerDisplay";
import reducerCompany from "./reducerCompany";
import reducerItems from "./reducerItems";
import reducerInvoices from "./reducerInvoices";
import reducerInvoiceTemplates from "./reducerInvoiceTemplates";
import reducerInvoicesOCR from "./reducerInvoicesOCR";
import reducerIntegrationData from "./reducerIntegrationData";
import reducerLinkedIntegrationData from "./reducerLinkedIntegrationData";
import reducerUsers from "./reducerUsers";
import reducerCustomers from "./reducerCustomers";
import reducerSuppliers from "./reducerSuppliers";
import reducerInvoicePayables from "./reducerInvoicePayables";
import reducerPayableOut from "./reducerPayableOut";
import reducerBankAccounts from "./reducerBankAccounts";
import reducerTaxForms from "./reducerTaxForms";
import reducerIntegrationPendingData from "./reducerIntegrationPendingData";
import reducerLinkedIntegrationPendingData from "./reducerLinkedIntegrationPendingData";
import reducerIntegrationErrors from "./reducerIntegrationErrors";
import reducerSubscriptions from "./reducerSubscriptions";
import reducerSubscriptionCustomers from "./reducerSubscriptionCustomers";
import reducerMailRecords from "./reducerMailRecords";

export default combineReducers({
  auth: reducerAuth,
  company: reducerCompany,
  items: reducerItems,
  invoices: reducerInvoices,
  invoiceTemplates: reducerInvoiceTemplates,
  invoicePayables: reducerInvoicePayables,
  payableOut: reducerPayableOut,
  invoicesOCR: reducerInvoicesOCR,
  display: reducerDisplay,
  integrationData: reducerIntegrationData,
  linkedIntegrationData: reducerLinkedIntegrationData,
  integrationPendingData: reducerIntegrationPendingData,
  linkedIntegrationPendingData: reducerLinkedIntegrationPendingData,
  integrationErrors: reducerIntegrationErrors,
  users:reducerUsers,
  suppliers:reducerSuppliers,
  customers:reducerCustomers,
  bankAccounts:reducerBankAccounts,
  taxForms:reducerTaxForms,
  subscriptions:reducerSubscriptions,
  subscriptionCustomers:reducerSubscriptionCustomers,
  mailRecords:reducerMailRecords
});
