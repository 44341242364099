import {
  SIGN_IN,
  SIGN_OUT,
  SIGN_IN_ERROR,
  ADD_INTEGRATION,
  REMOVE_INTEGRATION,
  SIGN_IN_UID,
  SIGN_IN_COOKIE
} from '../actionType';
import {fServerPost} from "../../functions/UtilityFunctions";
import {
  SERVER_INTEGRATION_DATA,
  URL_SERVER_AUTH
} from '../../api/apiUrl';

export const actionAuthSignIn = (formValues) => {
  return async (dispatch) => {
    const data = await fServerPost(
      URL_SERVER_AUTH,
      formValues,
      "login"
    )
    if (data.status === "OK") {
      //check if there is multiple
      const linkedUid = data.info?.linkedUid ?? [];
      if (linkedUid.length < 2) {
        dispatch({
          type: SIGN_IN,
          payload: data.info
        })
      }
    } else {
    dispatch({
       type: SIGN_IN_ERROR,
       payload: data.msg
     })
    }
    return data;
  }
};

export const actionAuthSignInUid = (user) => {
  return async (dispatch) => {
    const data = await fServerPost(
      URL_SERVER_AUTH,
      user,
      "loginUid"
    )
    if (data.status === "OK") {
      dispatch({
        type: SIGN_IN_UID,
        payload: data.info
      })
    } else {
      dispatch({
        type: SIGN_IN_ERROR,
        payload: data.msg
      })
    }
    return data;
  }
};

export const actionAuthSwitchUid = (user) => {
  return async (dispatch) => {
    const data = await fServerPost(
      URL_SERVER_AUTH,
      user,
      "loginUid"
    )
    if (data.status !== "OK") {
      dispatch({
        type: SIGN_IN_ERROR,
        payload: data.msg
      })
    }
    return data;
  }
};

export const actionAuthSignInCookie = () => {
  return async (dispatch) => {
    const data = await fServerPost(
      URL_SERVER_AUTH,
      {},
      "loginCookie"
    )
    if (data.status === "OK" && data.info) {
      dispatch({
        type: SIGN_IN_COOKIE,
        payload: data.info
      })
    } else {
      dispatch({
        type: SIGN_IN_ERROR,
        payload: data.msg
      })
    }
  }
};

export const actionAuthSignOut = () => {
  return async (dispatch) => {
    fServerPost(
      URL_SERVER_AUTH,
      {},
      "logout"
    ).then(null)
    dispatch({
      type: SIGN_OUT
    });
  }
}

export const actionAuthAddIntegration = (integration) => {
  return async (dispatch) => {
    dispatch({
      type: ADD_INTEGRATION,
      payload: integration
    })
  }
}

export const actionAuthRemoveIntegration = () => {
  return async (dispatch) => {
    const data = await fServerPost(
      SERVER_INTEGRATION_DATA,
      {},
      "disconnect"
    )
    if (data.status === "OK") {
      dispatch({
        type: REMOVE_INTEGRATION
      })
    }
    return data;
  }
}
