import {GET_COMPANY_INFO, PUSH_COMPANY_INFO, SIGN_OUT} from "../actions/actionType";

const INITIAL_STATE = {};

const reducerCompany = (state=INITIAL_STATE,action) => {
  switch (action.type) {
    case GET_COMPANY_INFO:
      return {...state, ...action.payload};
    case PUSH_COMPANY_INFO:
      return {...state, ...action.payload};
    case SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default reducerCompany;
