import '../styles/mhStyle.css';

import React, {useEffect, Suspense} from 'react';
import {connect} from 'react-redux';
import Cookies from 'js-cookie';

import {actionAuthSignInCookie} from "../actions/actionAuth";
import {actionWindowResize} from "../actions/actionDisplay";
import GetWindowSize from "../functions/GetWindowSize";
import ComponentLoading from "./ComponentLoading";
import {COOKIE_AUTH_USER_SESSION_ID} from "../api/apiUrl";

//splitting the Home and App pages to reduce load
const Home = React.lazy(()=> import('./home/Home'));
const App = React.lazy(()=>import('./app/App'));
const Public = React.lazy(()=>import('./public/Public'));
const Token = React.lazy(()=>import('./token/Token'));

const Main = (props) => {

  const {actionAuthSignInCookie,actionWindowResize} = props;
  const windowSize = GetWindowSize();
  //monitor window resize
  useEffect(() => {
    actionWindowResize(windowSize)
  },[windowSize,actionWindowResize]);

  const urlPublicPath = String(window.location.href).includes("/#/public/");
  const urlTokenPath = String(window.location.href).includes("/#/token/");

  //check if cookie or session id is set. If so, try to log in using cookie
  useEffect(() => {
    if (!urlPublicPath) {
      const authUserSessionId = Cookies.get(COOKIE_AUTH_USER_SESSION_ID);
      if (authUserSessionId) {
        actionAuthSignInCookie();
      }
    }
    //eslint-disable-next-line
  },[]);

  if (urlPublicPath) {
    return (
      <Suspense fallback={<ComponentLoading/>}>
        <Public window={props.window}/>
      </Suspense>
    )
  }
  if (urlTokenPath) {
    return (
      <Suspense fallback={<ComponentLoading/>}>
        <Token window={props.window}/>
      </Suspense>
    )
  }
  if (props.auth.isSignedIn) {
    return (
      <Suspense fallback={<ComponentLoading/>}>
        <App
          auth={props.auth}
          window={props.window}
          company={props.company}
          subscriptions={props.subscriptions}
        />
      </Suspense>
    );
  }
  return (
    <Suspense fallback={<ComponentLoading/>}>
      <Home window={props.window}/>
    </Suspense>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    company: state.company,
    window: state.display.window,
    subscriptions: state.subscriptions
  }
}

export default connect(mapStateToProps,  {
  actionAuthSignInCookie,
  actionWindowResize
})(Main);