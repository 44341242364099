import React from 'react';
import Image from "../components/utilities/Image";
import {IMG_LOGO_ONLY} from "../media";
import {InProgress} from "../components/utilities/UtilityComponents";

const ComponentLoading = () => {
  return (
    <div className="container vh-100 d-flex align-items-center">
      <div className="row w-100 border-primary-light rounded-xxl shadow-lg justify-content-center py-5 text-app-8 align-items-center">
        <Image img={IMG_LOGO_ONLY}/><span className={"ml-2"}>Loading...</span><InProgress/>
      </div>
    </div>
  )
}

export default ComponentLoading;