import React from 'react';

const Image = (props) => {
  return (
    <>
      <img src={props.img.src} width={props.img.width} height={props.img.height} alt={props.img.alt} className={props.imgClass}/>
    </>
  )
}

export default Image;