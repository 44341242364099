import {
  PUSH_MAIL_RECORDS,
  REFRESH_MAIL_RECORDS,
  GET_MAIL_RECORDS,
  SIGN_OUT
} from "../actions/actionType";

const INITIAL_STATE = {};

const reducerMailRecords = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_MAIL_RECORDS:
      return {...state, ...action.payload};
    case REFRESH_MAIL_RECORDS:
      return action.payload;
    case PUSH_MAIL_RECORDS:
      return {...state, ...action.payload};
    case SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default reducerMailRecords;
