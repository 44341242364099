import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';
import reduxThunk from 'redux-thunk';

import ErrorBoundary from "./pages/ErrorBoundary";
import Main from "./pages/Main";
import reducers from "./reducers";

const isDevelopment = process.env.REACT_APP_ENV === 'development';

const composeEnhancers = (isDevelopment && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);

ReactDOM.render(
<ErrorBoundary>
    <Provider store={store}>
      <Main />
    </Provider>
  </ErrorBoundary>
,document.querySelector('#root'))

// eslint-disable-next-line react-hooks/exhaustive-deps
