import img_logo from './logo/mhLogo_full_light.svg';
import img_logo_dark from './logo/mhLogo_full_dark.svg';
import img_logo_only from './logo/mhLogo_only_light.svg';
import img_auth from './illustration/img_mh_auth.svg';
import img_company_1 from './illustration/img_mh_company_1.svg';
import img_company_2 from './illustration/img_mh_company_2.svg';
import img_support from './illustration/img_mh_inprogress2.svg';
import img_account_number  from './illustration/img_account_number.svg';
import img_account_check from './illustration/img_account_check.svg';
import img_integration_qbo_connect from './illustration/img_integration_qbo_connect.svg';


export const IMG_LOGO = {src2:img_logo,src:img_logo_dark,alt:"MakersHub Logo",width:"163px",height:"30px"};
export const IMG_LOGO_ONLY = {src:img_logo_only,alt:"MakersHub Logo",width:"30px",height:"30px"};
export const IMG_AUTH = {src:img_auth,alt:"MakersHub Auth"};

export const IMG_COMPANY_1 = {src:img_company_1,alt:"MakersHub Company Structure"};
export const IMG_COMPANY_2 = {src:img_company_2,alt:"MakersHub Company Details"};
export const IMG_SUPPORT = {src:img_support,alt:"MakersHub Support"};

export const IMG_ACCOUNT_NUMBER = {src:img_account_number,alt:"Bank Account Illustration"};
export const IMG_ACCOUNT_CHECK = {src:img_account_check,alt:"Bank Account Illustration"};

export const IMG_INTEGRATION_QBO_CONNECT = {src:img_integration_qbo_connect,alt:"QuickBooks Online Connect"}
