import {
  SIGN_IN,
  SIGN_IN_COOKIE,
  SIGN_IN_UID,
  SIGN_OUT,
  SIGN_IN_ERROR,
  ADD_CID_TO_AUTH,
  ADD_INTEGRATION,
  REMOVE_INTEGRATION,
  PUSH_COMPANY_USERS
} from "../actions/actionType";

const INITIAL_STATE = {};

const reducerAuth = (state=INITIAL_STATE,action) => {
  switch (action.type) {
    case SIGN_IN:
    case SIGN_IN_COOKIE:
    case SIGN_IN_UID:
      return {...state, ...action.payload};
    case SIGN_IN_ERROR:
      return {...state, error:action.payload};
    case SIGN_OUT:
      return INITIAL_STATE;
    case ADD_CID_TO_AUTH:
      return {...state, cid:action.payload};
    case ADD_INTEGRATION:
      const newIntegration = {...state.integration,...action.payload};
      return {...state, integration:newIntegration};
    case REMOVE_INTEGRATION:
      return {...state, integration:null};
    case PUSH_COMPANY_USERS:
      const userData = Object.values(action.payload);
      if (userData.length) {
        const user = userData[0];
        if (user.id && Number(user.id) === Number(state.uid)) {
          return {...state,accessLevel:user.access_level,time_tracking:user.time_tracking,role:user.role,admin:user.admin}
        }
      }
      return state;
    default:
      return state;
  }
}

export default reducerAuth;
