import {
  GET_INVOICES_INFO,
  REFRESH_INVOICES_INFO,
  PUSH_INVOICES_INFO,
  DELETE_INVOICE,
  SIGN_OUT,
  PUSH_PARENT_INVOICE_INFO,
  COMBINE_INVOICES
} from "../actions/actionType";
import _ from "lodash";

const INITIAL_STATE = {};

const reducerInvoices = (state=INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INVOICES_INFO:
      return {...state,...action.payload};
    case REFRESH_INVOICES_INFO:
      return action.payload;
    case PUSH_INVOICES_INFO:
      return {...state,...action.payload};
    case PUSH_PARENT_INVOICE_INFO:
      const newParentInvoiceRecord = {...state[action.payload.id],...action.payload};
      return {...state,[action.payload.id]:newParentInvoiceRecord};
    case COMBINE_INVOICES:
      const updatedGroupState = {...state,[action.payload.id]:action.payload};
      return _.pickBy(updatedGroupState,(value)=>{
        return Number(value.parent_id) !== Number(action.payload.id)
      })
    case DELETE_INVOICE:
      return _.pickBy(state,(value,key)=>{
        return Number(key) !== Number(action.payload.id) && Number(value.parent_id) !== Number(action.payload.id)
      });
    case SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default reducerInvoices;
