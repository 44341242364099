import {GET_INVOICE_TEMPLATES, PUSH_INVOICE_TEMPLATE, DELETE_INVOICE_TEMPLATE, SIGN_OUT} from "../actions/actionType";
import _ from 'lodash';

const INITIAL_STATE = {};

const reducerInvoiceTemplates = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INVOICE_TEMPLATES:
      return {...state, ...action.payload};
    case PUSH_INVOICE_TEMPLATE:
      return {...state, [action.payload.id]:action.payload};
    case DELETE_INVOICE_TEMPLATE:
      return _.omit(state, action.payload.id);
    case SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default reducerInvoiceTemplates;