import {PUSH_INTEGRATION_DATA, REMOVE_INTEGRATION_DATA, SIGN_OUT} from "../actions/actionType";

const INITIAL_STATE = {};

const reducerIntegrationData = (state=INITIAL_STATE, action) => {
  switch (action.type) {
    case PUSH_INTEGRATION_DATA:
      return {...state, ...action.payload};
    case REMOVE_INTEGRATION_DATA:
    case SIGN_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
export default reducerIntegrationData;
